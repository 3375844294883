import { Colors } from '@spotahome/ui-library';

import createMapUtils from './maps-utils';

const createMarkerOverlay = () => {
  if (!window.google || !window.google.maps) {
    console.error('Google Maps API not available yet.');
    return null;
  }

  return class MarkerOverlay extends window.google.maps.OverlayView {
    constructor(marker) {
      super();
      this.marker = marker;
      this.div = null;
    }
    onAdd() {
      this.div = document.createElement('div');
      this.div.innerHTML = this.marker.extraLabel;
      this.div.style.position = 'absolute';
      this.div.style.background = Colors['secondary-blue'];
      this.div.style.color = 'black';
      this.div.style.width = '26px';
      this.div.style.height = '24px';
      this.div.style.display = 'flex';
      this.div.style.alignItems = 'center';
      this.div.style.justifyContent = 'center';
      this.div.style.borderRadius = '50%';
      this.div.style.fontSize = '14px';
      this.div.style.cursor = 'pointer';
      this.div.style.fontWeight = 'bold';
      this.div.style.boxShadow = '0px 2px 5px rgba(0,0,0,0.3)';
      this.div.style.userSelect = 'none';
      this.div.style.zIndex = this.marker.getZIndex();

      this.getPanes().overlayMouseTarget.appendChild(this.div);
    }

    draw() {
      if (!this.div) return;

      const projection = this.getProjection();
      const position = projection.fromLatLngToDivPixel(
        this.marker.getPosition()
      );

      if (position) {
        this.div.style.left = `${position.x + 20}px`;
        this.div.style.top = `${position.y - 40}px`;
      }
    }

    onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }
  };
};

export class Marker {
  constructor(
    map,
    {
      lat,
      lng,
      text,
      zIndex,
      className,
      markerIcon,
      isBooked,
      multiListingCount
    }
  ) {
    this.gMaps = createMapUtils();
    this.markerIcon = markerIcon;

    const markerOpts = {
      icon: this.markerIcon,
      position: { lat: parseFloat(lat), lng: parseFloat(lng) },
      label: text ? { text, className } : null,
      map,
      zIndex,
      extraLabel: multiListingCount > 0 ? `${multiListingCount}` : ''
    };

    this.isBooked = isBooked;
    this.multiListingCount = multiListingCount;
    this.baseZIndex = zIndex;
    this.visible = true;
    this.googleMarker = this.gMaps.createMarker(markerOpts);
    this.map = map;

    if (this.isBooked) {
      this.googleMarker.setCursor('default');
    }

    if (this.multiListingCount > 0) {
      const MarkerOverlay = createMarkerOverlay();
      this.bubbleOverlay = new MarkerOverlay(this.googleMarker);
      this.bubbleOverlay.setMap(map);
    } else {
      console.error('Google Maps API not available yet.');
    }
  }

  addListener = (eventName, callback) => {
    this.gMaps.clearListeners(this.googleMarker, eventName);
    this.gMaps.addListener(this.googleMarker, eventName, callback);
  };

  isVisible = () => this.visible;

  remove = () => {
    this.gMaps.clearInstanceListeners(this.googleMarker);
    this.visible = false;
    this.googleMarker.setMap(null);

    if (this.bubbleOverlay) {
      this.bubbleOverlay.setMap(null);
    }
  };

  display = () => {
    this.visible = true;
    this.googleMarker.setMap(this.map);
    if (this.bubbleOverlay) {
      this.bubbleOverlay.setMap(this.map);
    }
  };

  updateIcon = ({ className, zIndex = this.baseZIndex }) => {
    this.googleMarker.setZIndex(zIndex);
    this.googleMarker.setIcon(this.markerIcon);

    if (
      this.googleMarker.label &&
      (this.googleMarker.label.text || this.googleMarker.label.text === '')
    ) {
      this.googleMarker.setLabel({
        className,
        text: this.googleMarker.label.text
      });
    }
  };

  getBoundaries = () => this.googleMarker.getPosition();
}

const createMarker = (map, opts) => new Marker(map, opts);
export default createMarker;
