/* eslint-disable class-methods-use-this */
import defaults from 'lodash/defaults';
import differenceBy from 'lodash/differenceBy';

import debounce from '../../../../utils/debounce';
import AdUrlService from '../../../../utils/ad-url-service';

import createClustersAPI from './clusters-api';
import createMarkersAPI from './markers-api';
import createMapUtils from './maps-utils';
import createPoiAPI from './poi-api';

const POI_ZOOM_LEVEL = 16;

const LABEL_CLASSES = {
  default: 'marker',
  hover: 'marker--hover',
  active: 'marker--active',
  booked: 'marker--booked'
};
const ZOOM_CHANGED_DELAY = 4000;
const DRAGEND_DELAY = 1000;
const IDLE_DELAY = 300;
const MAX_MARKER_AMOUNT = 150; // maximum amount of markers that will be shown in the map

const DEFAULT_CONFIG = (googleMaps, deviceType) => ({
  zoom: 14,
  disableDefaultUI: true,
  disableDoubleClickZoom: false,
  mapTypeControl: false,
  fullscreenControlOptions: {
    position: googleMaps.ControlPosition.RIGHT_BOTTOM
  },
  zoomControl: deviceType === 'desktop',
  zoomControlOptions: {
    position: googleMaps.ControlPosition.LEFT_BOTTOM
  },
  minZoom: 5,
  draggable: true,
  scrollwheel: true,
  gestureHandling: 'greedy'
});

const POI_MARKER_PATH =
  'https://static.spotahome.com/map/marker-point-of-interest.svg';

class MapsAPI {
  constructor(
    mapId,
    options = {},
    markerOpts = {},
    onMapDrag = () => {},
    initializePosition = true,
    deviceType = null,
    getDesktopMapHomecardExp = () => null
  ) {
    this.options = options;
    this.initializePosition = initializePosition;
    this.mapId = mapId;
    this.gMaps = createMapUtils();
    this.onMapDrag = onMapDrag;

    this.map = this.gMaps.createMap(
      document.getElementById(mapId),
      defaults(this.options, DEFAULT_CONFIG(this.gMaps, deviceType))
    );

    if (this.options.transitLayer) {
      const tL = this.gMaps.createLayer();
      tL.setMap(this.map);
    }

    if (this.options.showClusters) {
      this.markersAPI = createClustersAPI(this.map, {
        onMarkerClick: markerOpts.onMarkerClick,
        onMarkerMouseOver: markerOpts.onMarkerMouseOver,
        onMarkerMouseOut: markerOpts.onMarkerMouseOut,
        onClusterClick: markerOpts.onClusterClick,
        onClusterMouseOver: markerOpts.onClusterMouseOver,
        onClusterMouseOut: markerOpts.onClusterMouseOut,
        getMarkerText: markerOpts.getMarkerText
      });
    } else {
      this.markersAPI = createMarkersAPI(
        this.map,
        {
          onMarkerClick: markerOpts.onMarkerClick,
          onMarkerMouseOver: markerOpts.onMarkerMouseOver,
          onMarkerMouseOut: markerOpts.onMarkerMouseOut,
          getMarkerText: markerOpts.getMarkerText,
          labelClasses: LABEL_CLASSES,
          markerIcon: markerOpts.markerIcon
        },
        getDesktopMapHomecardExp
      );
    }

    this.poiAPI = createPoiAPI(this.map, {
      markerIcon: POI_MARKER_PATH,
      circleColor: '#ffccef',
      circleRadius: markerOpts.poiRadius || 1000,
      labelClasses: LABEL_CLASSES
    });

    this.addMapListeners(onMapDrag);
  }

  addMapListeners = () => {
    this.addMapListener('dragend', this.handleDragEnd);
    this.addMapListener('zoom_changed', this.handleZoomChange);
    this.addMapListener('idle', this.handleIdle);
  };

  onBoundsChange = () => {
    if (this.options.onZoomChange) {
      this.options.onZoomChange(this.getBounds());
    }
  };

  handleIdle = debounce((...args) => {
    if (this.options.onIdle) {
      this.options.onIdle(...args);
    }
  }, IDLE_DELAY);

  handleZoomChange = debounce(this.onBoundsChange, ZOOM_CHANGED_DELAY);

  handleDragEnd = debounce(() => {
    const lat = this.map.getCenter().lat();
    const lng = this.map.getCenter().lng();
    const mapCenterQueryParamValues = `${lat},${lng}`;

    AdUrlService.setQueryParameter('mapCenter', mapCenterQueryParamValues);
    this.onBoundsChange();
    this.onMapDrag(mapCenterQueryParamValues);
  }, DRAGEND_DELAY);

  addMapListener = (eventName, callback) => {
    this.map.addListener(eventName, callback);
  };

  addPointOfInterest = pointOfInterest => {
    this.poiAPI.addPointOfInterest({
      lat: pointOfInterest.lat,
      lng: pointOfInterest.lng
    });

    const { lat, lng } = this.poiAPI.getPoiBoundaries();
    this.setCenter({ lat, lng });
    this.setZoom(POI_ZOOM_LEVEL);
  };

  removePointOfInterest = () => {
    this.poiAPI.removePointOfInterest();
  };

  updateMarkers = (prevMarkers, newMarkers, pointOfInterest) => {
    if (this.options.showClusters) {
      this.removeAllMarkers();
      this.addMarkers(newMarkers, pointOfInterest);
    } else {
      const markersToBeRemoved = differenceBy(prevMarkers, newMarkers, 'id');
      this.removeMarkers(markersToBeRemoved);
      this.addMarkers(newMarkers, pointOfInterest);
    }
  };

  getMapDimensions = (substractPadding = false) => {
    const mapDiv = document.getElementById(this.mapId);
    if (!mapDiv) {
      return false;
    }
    const padding = substractPadding ? 2 * this.options.padding : 0;
    return {
      width: mapDiv.offsetWidth - padding,
      height: mapDiv.offsetHeight - padding
    };
  };

  coordsInBounds = coords => {
    const bounds = this.map.getBounds();
    const latlng = this.gMaps.createLatLng(coords.lat, coords.lng);
    const mapDimensions = this.getMapDimensions();

    if (!bounds || !this.options.padding || !mapDimensions) {
      return true;
    }

    if (bounds.contains(latlng)) {
      const { width: mapWidth, height: mapHeight } = mapDimensions;

      const scale = 2 ** this.map.getZoom();
      const projection = this.map.getProjection();
      const coordsPoint = projection.fromLatLngToPoint(latlng);
      const norhtWestPoint = projection.fromLatLngToPoint(
        this.gMaps.createLatLng(
          bounds.getNorthEast().lat(),
          bounds.getSouthWest().lng()
        )
      );

      const xOffset = Math.floor((coordsPoint.x - norhtWestPoint.x) * scale);
      const yOffset = Math.floor((coordsPoint.y - norhtWestPoint.y) * scale);

      const inXLimits =
        xOffset - this.options.padding > 0 &&
        xOffset + this.options.padding < mapWidth;
      const inYLimits =
        yOffset - this.options.padding > 0 &&
        yOffset + this.options.padding < mapHeight;

      return inXLimits && inYLimits;
    }

    return false;
  };

  addMarkers = markerList => {
    if (markerList?.length) {
      if (this.initializePosition) {
        const markerCoords = markerList.map(marker => ({
          lat: parseFloat(marker.coord[1]),
          lng: parseFloat(marker.coord[0])
        }));
        this.fitBoundsToCoords(markerCoords);
        this.initializePosition = false;
      }
      this.markersAPI.addMarkers(
        markerList,
        this.getMapDimensions(true),
        this.getBounds()
      );
    }
  };

  removeAllMarkers = () => {
    this.markersAPI.removeAllMarkers();
  };

  removeMarkers = markerList => {
    this.markersAPI.removeMarkers(markerList.map(marker => marker.id));
  };

  activeMarker = markerId => {
    this.markersAPI.setHighlightedIcon(markerId, 'active');
  };

  removeMapListeners = () => {
    this.gMaps.clearInstanceListeners(this.map);
  };

  setCenter = opts => {
    this.map.setCenter({
      lat: parseFloat(opts.lat),
      lng: parseFloat(opts.lng)
    });
  };

  mustCutMarkerList(markerList) {
    const tooManyMarkers = markerList.length > MAX_MARKER_AMOUNT;

    if (this.options.showClusters) {
      const currentZoom = this.map.getZoom();
      const maxZoom = this.markersAPI.getMaxZoom();
      return tooManyMarkers && currentZoom >= maxZoom;
    }
    return tooManyMarkers;
  }

  fitBoundsToMarkers = () => {
    const bounds = this.gMaps.createLatLngBounds();
    const markersBoundaries = this.markersAPI.getMarkersBoundaries();
    const poiBoundaries = this.poiAPI.getPoiBoundaries();

    [poiBoundaries, ...markersBoundaries].filter(Boolean).forEach(boundary => {
      bounds.extend(boundary);
    });

    // avoid zooming in when there is only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const northEastLat = bounds.getNorthEast().lat();
      const northEastLng = bounds.getNorthEast().lng();

      bounds.extend(
        this.gMaps.createLatLng(northEastLat + 0.01, northEastLng + 0.01)
      );
      bounds.extend(
        this.gMaps.createLatLng(northEastLat - 0.01, northEastLng - 0.01)
      );
    }

    this.map.fitBounds(bounds);
  };

  fitBoundsToCoords = (markersCoords = []) => {
    const bounds = this.gMaps.createLatLngBounds();
    markersCoords.forEach(coords => {
      if (coords.lat && coords.lng) {
        bounds.extend(this.gMaps.createLatLng(coords.lat, coords.lng));
      }
    });
    this.map.fitBounds(bounds);
  };

  increaseZoom = () => {
    const currentZoom = this.map.getZoom();
    this.map.setZoom(currentZoom + 1);
  };

  setZoom = zoomValue => {
    this.map.setZoom(zoomValue);
  };

  getBounds = () => {
    const mapBounds = this.map.getBounds();
    if (!mapBounds) {
      return false;
    }
    const northEastBoundary = mapBounds.getNorthEast();
    const northEast = {
      lng: northEastBoundary.lng(),
      lat: northEastBoundary.lat()
    };

    const southWestBoundary = mapBounds.getSouthWest();

    const southWest = {
      lng: southWestBoundary.lng(),
      lat: southWestBoundary.lat()
    };

    return { northEast, southWest };
  };
}

export default MapsAPI;
